@use '@angular/material' as mat;
@use '../m3-theme.scss' as theme;
@import "./app/shared/styles/utilities.scss";
@import 'app/shared/styles/_variables.scss';

html,
body {
  margin: 0;
  font-family: Roboto;
  background: #fafafa;
  // Apply the light theme by default
  // @include mat.all-component-themes(theme.$light-theme);
  @include mat.core-theme(theme.$light-theme);
  @include mat.button-theme(theme.$light-theme);
  @include mat.card-theme(theme.$light-theme);
}

.text-grey {
  color: #cccccc;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}

mat-card.mat-mdc-card {
  margin: 0 0 10px 0;
}

.modal {
  padding: 20px;
}

.mat-mdc-tab-body {
  margin-top: 24px;
}

.mat-card-photo-thumb {
  margin: 3px;
  width: 40px;
  height: 40px;
  border-radius: 100em;
}

.datagrid {
  overflow: hidden;
}

.datagrid table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.datagrid table td,
.datagrid table th {
  padding: 10px 9px;
}

.datagrid table thead th {
  color: rgba(0, 0, 0, 0.74);
  font-size: 12px;
  font-weight: bold;
}

.datagrid table thead {
  border-bottom-color: #e2e2e2;
  border-bottom-width: thin;
  border-bottom-style: solid;
}

.datagrid table thead th:first-child {
  border: none;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}

.datagrid table tbody td {
  color: rgba(0, 0, 0, 0.64);
  font-size: 12px;
  font-weight: normal;
}

.datagrid table tbody tr:hover {
  background-color: #efefef;
}

.datagrid table tbody .alt td {
  color: #747080;
}

.datagrid table tbody td:first-child {
  color: rgba(0, 0, 0, 0.64);
  border-left: none;
  font-weight: bold;
}

.datagrid table tbody tr:last-child td {
  border-bottom: none;
}

.datagrid table tfoot {
  border: none;
}

.d-print-block {
  display: none;
}

// TODO: refactor this for other pages to print
@media print {
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  * {
    box-shadow: none !important;
  }

  mat-sidenav,
  mat-toolbar {
    display: none !important;
  }

  app-root {
    display: none; // TODO: hide app-root only whe popup is active
  }

  .cdk-overlay-container {
    position: static;

    .cdk-global-overlay-wrapper {
      display: none;
    }

    .cdk-global-overlay-wrapper:last-child {
      display: flex;
      position: static;

      .mat-mdc-dialog-container {
        overflow: inherit;
      }

      .modal {
        max-height: inherit;

        .modal_body {
          overflow: inherit;
        }
      }
    }
  }

  .d-print-none {
    display: none !important;
  }

  .d-print-block {
    display: block !important;
  }

  .print-label {
    margin-bottom: -15px;
    color: #797979;
    font-size: 90%;
  }

  .print-text {
    padding: 15px 0;
  }

  .radio {
    padding-top: 15px;
  }

  .modal_footer,
  .modal_header {
    display: none !important;
  }

  .cdk-overlay-pane {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: inherit !important;
    min-height: 100% !important;
  }

  .mat-form-field-empty {
    display: none !important;
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.half {
    max-width: 50%;
  }

  button {
    align-self: center;
  }

  mat-form-field {
    flex: 2;
    margin: 0 5px 0;

    &.remarks {
      min-width: 100%;
    }

    &.small {
      flex: 1;
      max-width: calc(25% - 10px);
    }

  }
}

.carriage_returns {
  white-space: pre-line;
}

.modal_title {
  font-size: 1.2em;
}

.modal_subtitle {
  color: #928989;
  font-size: 0.9em;
}

#app_print_area_container {
  display: none;
}

@media print {
  body {
    background-color: #ffffff;
  }

  body * {
    visibility: hidden;
  }

  #app_print_area_container {
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    background-color: #ffffff;
    position: absolute;
    z-index: 15000000;
    height: auto;
  }

  #app_print_area_container * {
    visibility: visible;
  }

  .print_area_body {
    width: 100%;
    overflow: auto;
    padding: 0;
  }
}

#exportContentContainer {
  height: 0;
  width: 0;
  overflow: hidden;
}

.disabled_element {
  pointer-events: none;
  opacity: 0.75;
}

// css updates on material default
mat-form-field {
  @include mat.form-field-density(-2);
}

.mat-mdc-card-title {
  font-weight: 400 !important;
}

.mat-expansion-panel-header {
  font-weight: 400 !important;
}

.mat-mdc-select-panel {
  //replace with panelWidth auto from angilar 16.1
  min-width: fit-content !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  //replace with panelWidth auto from angilar 16.1
  white-space-collapse: collapse !important;
  text-wrap: nowrap !important;
}

// List
.list {
  margin: auto;
  border: solid 1px #ccc;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}

.list-box {
  padding: 1px 15px;
  border-bottom: solid 1px #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.list-box:last-child {
  border: none;
}

.list.cdk-drop-list-dragging .list-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.inline-list-main-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;

  .inline-list-container {
    position: relative;
    margin-bottom: 0 !important;
    overflow: hidden !important;
    height: calc(100vh - 105px);

    .inline-list-table-container {
      position: relative;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .inline-list-paginator {
      margin-top: auto !important;
    }
  }
  .inline-list-filter {
    max-height: calc(100vh - 105px);
    overflow-y: auto;
  }
}