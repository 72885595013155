@mixin scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparentize(#2427ff, 0.6);
    border-radius: 2px;
    border: none;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  &::-webkit-scrollbar-track {
    background: #b7b9b7;
  }
}

.carriage_returns {
  white-space: pre-line;
}

.cursor_pointer {
  cursor: pointer;
  outline: none;
}

.disabled_element {
  pointer-events: none;
  opacity: 0.6;
}

.text-muted {
  color: #a3a2a2;
}

.text-white {
  color: #ffffff;
}

.text-danger {
  color: #f44336 !important;
}

.text-underline {
  text-decoration: underline;
}

// text alignment utilities
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// padding utilities
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.p-5 {
  padding: 30px !important;
}

.pl-2 {
  padding-left: 10px !important;
}

.pt-4 {
  padding-top: 20px !important;
}

.py-4 {
  padding-top: 20px;
  padding-bottom: 20px;
}

// margin utilities
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.m-4 {
  margin: 20px !important;
}

.m-5 {
  margin: 30px !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

// overflow utilities
.o-hidden {
  overflow: hidden !important;
}

.hidden_element {
  height: 0;
  width: 0;
  overflow: hidden;
}

.o-auto {
  overflow: auto !important;
}

// width utilities
.w-0 {
  width: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

// font-size utilities
.fs-0-6 {
  font-size: 0.6em !important;
}

.fs-0-7 {
  font-size: 0.7em !important;
}

.fs-0-8 {
  font-size: 0.8em !important;
}

.fs-0-9 {
  font-size: 0.9em !important;
}

.fs-1 {
  font-size: 1em !important;
}

.fs-1-1 {
  font-size: 1.1em !important;
}

.fs-1-2 {
  font-size: 1.2em !important;
}

.fs-1-3 {
  font-size: 1.3em !important;
}

.fs-1-4 {
  font-size: 1.4em !important;
}

.fs-1-5 {
  font-size: 1.5em !important;
}

.fs-2 {
  font-size: 2em !important;
}

.fs-3 {
  font-size: 3em !important;
}

.fs-4 {
  font-size: 4em !important;
}

.fs-5 {
  font-size: 5em !important;
}

.card {
  border: 1px solid #cccccc;
  border-radius: 3px;
  overflow: hidden;

  .card-header {
    padding: 10px;
    background-color: #cccccc;
    font-size: 1.3em;
  }

  .card-body {
    padding: 10px;
  }
}
